.comliments-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.comliments-item{
    width: 100%;
    padding: 10.7px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background-color: #5185E8;
    gap: 40px;
}
.comliments-item *{
    color: white !important;
}
.comliments-position{
    font-weight: 700;
}
.comliments-item-left,.comliments-item-right{
    display: flex;
    align-items: center;
    gap: 4px;
}
.comliments-item-left img{
    height: 46.6px;
}
.comliments-text{
    line-height: 14px;
}