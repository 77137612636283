.load-image{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 4px;
    font-weight: 700;
    text-decoration: underline;
    background: none;
    padding: 0;
    font-size: 12px;
}
.survey-form{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.survey-form > p{
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    width: 270px;
    margin: 0 auto;
}
.survey-form textarea{
    border-radius: 14px;
    padding: 10px;
    resize: none;
    height: 118px;
}
.survey-form label{
    border-radius: 20px;
}
.survey-form label, .survey-form textarea{
    border: 2px solid #5185E8;
}
.survey-form-answers{
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 14px;
}
.survey-form-answers input{
    width: fit-content;
    max-width: 100px;
}
.survey-form-answers label{
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 calc(50% - 14px);
}