.task-list-title {
  color: #bababa;
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
}
.tasks-item .comliments-text {
  font-size: 14px;
}
.task-reward {
  display: flex;
  align-items: center;
}
.task-reward img {
  width: 22px;
}
.tasks-list--done .tasks-item{
background-color: #5185E866;
}