.settings-page {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.compliment-container {
  background-color: #5185e84d;
  border-radius: 20px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 14px;
}
.compliment-list {
  display: flex;
  justify-content: space-between;
}
.compliment-day-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.compliment-day-item span {
  font-size: 12px;
}
.compliment-day-progreess {
  height: 73px;
  background-color: #5185e84d;
  border-radius: 50px;
  width: 11px;
  display: flex;
  align-items: end;
}
.compliment-day-progreess-bar {
  display: block;
  width: inherit;
  border-radius: inherit;
  background-color: #bbfb4c;
}
.settings-page .form-select-container {
  background-color: #5185e84d;
}
.stat-reset {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
}
.stat-reset span{
    font-size: 12px;
    font-weight: 700;
}
.stat-reset .btn{
background: none;
border: 2px solid #5185E8;
padding: 12px;
}
