.home-page{
    display: flex;
    flex-direction: column;
    padding: 0;
}
header{
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header .btn{
    padding: 12px 24px;
}
header nav ul{
    cursor: pointer;
    display: flex;
    align-items: center;
}
header nav ul li{
    position: relative;
    padding: 7px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notif-counter{
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #BBFB4C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 700;
    font-size: 10px;
    border-radius: 50%;
    top: 4px;
    right: 2px;
}
.page-body{
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex: 1;
    padding: 20px 16px;
}
.page-body *{
    color: black;
}
.user-balances{
display: flex;
align-items: center;
justify-content: space-between;
}
.user-balance{
    display: flex;
    gap: 8px;
    align-items: center;
}
.user-balance img{
    width: 24px;
}
.user-balance span{
    font-size: 14px;
}
.survey-container{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
}
.survey-progress{
    background-color: #5185E84D;
    border-radius: 50px;
    padding: 3px 4px;
    width: 100%;
    height: 15px;
}
.survey-progress-bar{
    display: block;
    border-radius: inherit;
    height: 100%;
    background-color: #BBFB4C;
}
.survey-container h2{
    text-align: center;
}
.survey-question-block{
    display: flex;
    gap: 24px;
    align-items: stretch;
    flex-direction: column;
    min-width: 300px;
}
.survey-question-variants{
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 14px;
}
.survey-question-variants li{
    height: 68px;
    flex: 1 1 calc(50% - 14px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 2px solid #5185E8;
    font-size: 14px;
}
.survey-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.survey-actions .btn{
    background: none;
    padding: 0;
    gap: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}
