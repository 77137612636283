.loading-page {
  display: flex;
  flex-direction: column;
}
.loading-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-progress{
    width: 100%;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}
.loading-progress-container{
    background-color: hsla(165, 15%, 95%, 0.4);
    border-radius: 50px;
    width: 100%;
    height: 15px;
    padding: 3px 4px;
}
.loading-progress-value{
    font-size: 34px;
}
.loading-progress-bar{
    transition: .05s;
    height: 100%;
    border-radius: inherit;
    display: block;
    background-color: #BBFB4C;
}