@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
input
hr a {
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: white;
  letter-spacing: 1.5px;
  font-family: "Poppins", sans-serif;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: 0.8px;
}
#root{
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

html,
body {
    overflow-x: hidden;
    background-color: #5185e8;
    color: #fff;
}
h2{
  text-align: center;
  margin-bottom: 24px;
}
.page-wrapper{
    position: absolute;
    overflow: auto;
    overflow-x: hidden;
    inset: 0;
    padding: 24px 16px ;
}
.header-back{
  /* margin: 14px 0; */
}
.header-back .btn{
  padding: 14px 18px 14px 0 !important;
background: none;
padding: 0;
}
/* SETTINGS FORM */
form{
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
form label{
  font-weight: 700;
  font-size: 12px;
}

.form-row{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/* BUTTON */
.btn{
  cursor: pointer;
  background-color: #BBFB4C;
  padding:21px;
  border-radius: 50px;
  color: black;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  gap: 4px;
}
/* SELECT */
.form-select-container {
  background-color: #F0F4F34D;
  border-radius: 50px;
  transition: .2s;
  position: relative;
}
.form-select-container.open{
  border-radius: 20px;

}

.form-select-input,.form-select-header{
  padding: 23px 16px;
  width: 100%;
}
.form-select-input[type=text]::placeholder {
  font-size: 12px;
  color: rgb(245, 245, 245);
}
.form-select-input[type=text]::-webkit-input-placeholder {
  font-size: 12px;
  color: rgb(245, 245, 245);
}
.form-select-input[type=text]::-moz-placeholder {
  font-size: 12px;
  color: rgb(245, 245, 245);
}
.form-select-input[type=text]:-moz-placeholder {
  font-size: 12px;
  color: rgb(245, 245, 245);
}
.form-select-input[type=text]:-ms-input-placeholder {
  font-size: 12px;
  color: rgb(245, 245, 245);
}

.form-select-icon{
  position: absolute;
  width: 24px;
  height: 24px;
  top: 20px;
  right: 15px;
}
.form-select-options {
  padding: 6px 0;
  max-height: 170px;
  overflow: auto;
}
/* W3C standard
   сейчас только для Firefox */
   .form-select-options {
    scrollbar-width: auto;
    scrollbar-color: #BBFB4C #5185E84D;
  }
  
  /* для Chrome/Edge/Safari */
  .form-select-options::-webkit-scrollbar-button {
    display: none;
  }
  .form-select-options::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #5185E84D;
  }
  .form-select-options::-webkit-scrollbar-track {
    background: #5185E84D;
    padding-right: 10px;

  }
  .form-select-options::-webkit-scrollbar-thumb {
    background-color: #BBFB4C;
    border-radius: 50px;
  }
.form-select-option{
  cursor: pointer;
  padding: 12px 18px;
  /* Стили для каждой опции */
}

.form-select-option:hover {
  /* Стили для опции при наведении */
}

.form-select-no-options {
   padding: 23px 16px;
}
